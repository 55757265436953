declare global {
    interface Window {
        env: object;
    }
}

const checkEnv = (env: object) => {
    for (const key in env) {
        if (env[key] === undefined) {
            console.error(`ENV var ${key} should be set (found 'undefined')`);
            // throw new Error(`ENV var ${key} should be set (found 'undefined')`)
        }
    }
};

const windowEnv = typeof window !== "undefined" ? window.env : {};

const processEnv = {
    VITE_SENTRY_DSN_PUBLIC: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    VITE_SENTRY_ENVIRONMENT: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    VITE_SENTRY_TRACES_SAMPLE_RATE: import.meta.env
        .VITE_SENTRY_TRACES_SAMPLE_RATE,
    VITE_GOOGLE_API_KEY: import.meta.env.VITE_GOOGLE_API_KEY,
    VITE_SENTRY_ENABLED: import.meta.env.VITE_SENTRY_ENABLED,
};

const env = {
    ...processEnv,
    ...windowEnv,
};

checkEnv(env);

export default env;
